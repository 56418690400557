import { FormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';


export function MustMatch(new_password: string, confirm_password: string) {
    return (formGroup: FormGroup): ValidatorFn | ValidatorFn[] => {
        const newControl = formGroup.controls[new_password];
        const confirmControl = formGroup.controls[confirm_password];

        if (confirmControl.errors && !confirmControl.errors.mustMatch) {
            // return if another validator has already found an error on the confirmControl
            return;
        }

        // set error on confirmControl if validation fails
        if (newControl.value !== confirmControl.value) {
            confirmControl.setErrors({ mustMatch: true });
        } else {
            confirmControl.setErrors(null);
        }
    }
}

export function isValidPhoneNumber(value) {
    if (!value.match(/^(?:\+855|0)(?:\s*\d){8,9}$/)) {
        throw new Error("phone number is not valid");
    }
    return true;
}

export function isValidYearOfBirth(controlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);
  
      if (!control) {
        return null;
      }
  
      const currentYear = new Date().getFullYear();
      const minYear = currentYear - 100; // Assuming maximum age of 100 years
      const maxYear = currentYear - 13; // Assuming minimum age of 18 years
      
      const yearValue = control.value;
      
      if (yearValue && (yearValue < minYear || yearValue > maxYear)) {
        control.setErrors({ year_of_birth: true });
        return { year_of_birth: true };
      }
      return null;
    };
  }