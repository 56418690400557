<div class="store-container">
  <mat-sidenav-container>
    <mat-sidenav #drawer [(opened)]="isExpanded" [mode]="navbarMode">
      <div class="profile-box" [routerLink]="['/store-info']">
        <div class="profile">
          <div class="img" [style.background-image]="
              profileUrl
                ? 'url(' + profileUrl + '?token=' + token + ')'
                : 'url(' + 'assets/imgs/official-store.svg' + ')'
            "></div>
          <!-- <mat-icon
            class="shop-icon"
            svgIcon="icon-shop-profile"
            *ngIf="!profileUrl"
          ></mat-icon> -->
        </div>
        <h3 class="shop-name nowrap">
          {{ profile?.shops[0]?.name | uppercase }}
        </h3>
        <div class="divider"></div>
      </div>
      <div class="menu-wrapper">
        <div class="content">
          <h3 class="mat-h3 menu-title">
            {{ "StoreMode.ShopInfo" | translate }}
          </h3>
          <app-store-mode-menu></app-store-mode-menu>
        </div>
        <div class="content-button">
          <div class="divider"></div>
          <button mat-stroked-button class="profile-content" [routerLink]="['/home']">
            <div class="swap-img">
              <mat-icon class="icon-swap" svgIcon="icon-swap-store-mode">
                <app-profile-image class="profile-images" [profileStyle]="{ width: '24px', height: '24px' }"
                  className="title-md font-bold title-profile-shop" [profileImage]="
                    profile?.photo_url
                      ? profile?.photo_url + '?token=' + token
                      : 'assets/imgs/avatar-person.svg'
                  ">
                </app-profile-image>
              </mat-icon>
              <h4>{{ "SwitchToMyAccount" | translate }}</h4>
            </div>
          </button>
          <div>

          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="sidenav-content-wrapper">
        <app-store-mode-header (menuButtonClicked)="menuButtonClickHandler()"></app-store-mode-header>
        <div class="store-content-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>